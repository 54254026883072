import gql from 'graphql-tag'

export const QUIZ_QUESTION = gql`
  fragment quizQuestion on QuizQuestion {
    id
    question
    descriptionJson
    incorrectHint
    correctAnswerSummary
    questionNumber
    status
    savedAnswer
    triesRemaining
    ... on MultiChoiceQuizQuestion {
      options {
        letter
        value
        correct
      }
      multiSelect
    }
    ... on MadLibQuizQuestion {
      textParts {
        text
        validAnswers
      }
    }
  }
`
