import { COURSE_STEP_COMMENT } from 'gql/fragments/comment'
import {
  CONTINUE_ON_STEP_REFERENCE,
  COURSE,
  PUBLIC_COURSE,
} from 'gql/fragments/course'
import { COURSE_PAGE } from 'gql/fragments/coursePage'
import { COURSE_PROVIDER } from 'gql/fragments/provider'
import { COURSE_STEP } from 'gql/fragments/step'
import gql from 'graphql-tag'
import type { Course } from 'types/index'
import { QUIZ_QUESTION } from './fragments/quizQuestion'
import { COURSE_SEGMENT_BASE } from './fragments/segmentBase'
import { COURSE_STEP_BASE } from './fragments/stepBase'

export type CoursesData = {
  courses: Course[]
}

export const GET_PUBLIC_COURSES = gql`
  query getPublicCourses {
    courses: getPublicCourses {
      ...courseFields
    }
  }
  ${COURSE}
  ${COURSE_PROVIDER}
`

export const GET_CURRENT_COURSES = gql`
  query getCurrentCourses {
    courses: getCurrentCourses {
      id
      title
      slug
      overview
      imageUrl
      dateStart
      dateEnd
      status
      completedPercent
      canView
      finalGrade
      course {
        id
        slug
        period
        startType
        isFree
        timeframeWeeks
        hoursPerWeek
        priceAU
        priceNZ
        priceUS
        categories {
          id
          title
        }
        courseProvider {
          id
          title
          slug
        }
        programme {
          id
          title
          slug
        }
      }
      continueOnStep {
        ...continueOnStepReferenceFields
      }
    }
  }
  ${CONTINUE_ON_STEP_REFERENCE}
`

export const GET_RECOMMENDED_COURSES = gql`
  query getRecommendedCourses {
    courses: getRecommendedCourses {
      ...courseFields
    }
  }
  ${COURSE}
  ${COURSE_PROVIDER}
`

export const GET_COURSE_SEGMENT = gql`
  query getCourseSegment($slug: String!, $edition: String!, $reference: String!) {
    getCourseSegment(slug: $slug, edition: $edition, reference: $reference) {
      ...courseSegmentBaseFields
      courseSections {
        id
        title
        overview
        imageUrl
        courseSteps {
          ...courseStepBaseFields
        }
      }
      courseEdition {
        id
        title
        canView
        finalGrade
        continueOnStep {
          ...continueOnStepReferenceFields
        }
        course {
          pages {
            id
            title
            slug
          }
        }
        assessments {
          id
        }
      }
      assessments {
        id
        type
        title
        submissionDueDateTime
        status
        sacApplication {
          status
        }
        grade
        submission {
          id
          grade
          status
        }
        submissions(limit: 2) {
          id
          grade
          status
        }
      }
    }
  }
  ${COURSE_SEGMENT_BASE}
  ${COURSE_STEP_BASE}
  ${CONTINUE_ON_STEP_REFERENCE}
`

export const GET_COURSE_EDITION = gql`
  query getCourseEdition($slug: String!, $edition: String!) {
    getCourseEdition(slug: $slug, edition: $edition) {
      id
      completedPercent
      title
      slug
      canView
      finalGrade
      dateStart
      dateEnd
      assessments {
        id
      }
      course {
        id
        slug
        title
        period
        overview
        startType
        pages {
          id
          title
          slug
        }
        categories {
          id
          title
        }
        courseProvider {
          id
          title
          slug
          imageUrl
        }
      }
      courseSegments {
        ...courseSegmentBaseFields
        courseSections {
          id
          title
          courseSteps {
            id
            title
            reference
            status
            tags {
              type
            }
          }
        }
        assessments {
          id
          type
          title
          status
          submissionDueDateTime
          sacApplication {
            status
          }
          hasPendingRevoke
          grade
          submission {
            id
            grade
            status
          }
          submissions(limit: 2) {
            id
            grade
            status
          }
        }
      }
      continueOnStep {
        ...continueOnStepReferenceFields
      }
    }
  }
  ${COURSE_SEGMENT_BASE}
  ${CONTINUE_ON_STEP_REFERENCE}
`

export const GET_COURSE_STEP = gql`
  query getCourseStep($slug: String!, $edition: String!, $reference: String!) {
    getCourseStep(slug: $slug, edition: $edition, reference: $reference) {
      ...courseStepFields
      ... on CourseStepPage {
        contentJson
      }
      ... on CourseStepQuiz {
        introJson
        summaryJson
        quizQuestions {
          ...quizQuestion
        }
        nextQuestion {
          ...quizQuestion
        }
      }
      ... on CourseStepIframe {
        contentJson
        src
        height
      }
    }
  }
  ${COURSE_STEP}
  ${QUIZ_QUESTION}
`

export const GET_COURSE_EDITION_COMMENTS = gql`
  query getCourseEdition($slug: String!, $edition: String!) {
    getCourseEdition(slug: $slug, edition: $edition) {
      id
      title
      course {
        pages {
          id
          title
          slug
        }
      }
      assessments {
        id
      }
      courseSegments {
        id
        courseSections {
          id
          courseSteps {
            id
            title
            reference
            comments {
              ...courseStepCommentFields
            }
          }
        }
      }
    }
  }
  ${COURSE_STEP_COMMENT}
`

export const GET_COURSE_PAGE = gql`
  query getCoursePage($slug: String!, $edition: String!, $pageSlug: String!) {
    getCoursePage(slug: $slug, edition: $edition, pageSlug: $pageSlug) {
      ...coursePageFields
      course {
        id
        title
        slug
        pages {
          id
          title
          slug
        }
      }
    }
  }
  ${COURSE_PAGE}
`

export const GET_PUBLIC_COURSE = gql`
  query getPublicCourse($slug: String!) {
    course: getPublicCourse(slug: $slug) {
      ...publicCourseFields
    }
  }
  ${PUBLIC_COURSE}
  ${COURSE_PROVIDER}
`

export const GET_COURSE_ENROLMENTS = gql`
  query getCourseEnrolments($status: [EnrolmentStatus]) {
    enrolments: getCourseEnrolments(status: $status) {
      id
      status
      course {
        ...courseFields
        programme {
          id
          title
          slug
        }
      }
      courseIntake {
        id
        title
        slug
        enrolUrl
        timeframe
        courseEdition {
          id
          title
          slug
          overview
          imageUrl
          dateStart
          dateEnd
          canView
        }
        dateStart
        dateEnd
      }
      canPay
      canUpdate
    }
  }
  ${COURSE}
  ${COURSE_PROVIDER}
`
