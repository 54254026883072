import gql from 'graphql-tag'

export const SUBMISSION_FILE = gql`
  fragment assessmentSubmissionFileFields on AssessmentSubmissionFile {
    id
    createdAt
    updatedAt
    filename
    status
    canDownload
    downloadUrl
  }
`

export const SUBMISSION = gql`
  fragment assessmentSubmissionFields on AssessmentSubmission {
    id
    createdAt
    updatedAt
    submittedAt
    description
    language
    files {
      ...assessmentSubmissionFileFields
    }
    group
    criteriaFeedback {
      criteria
      description
      feedback
      feedbackMarker2
    }
    overallFeedback
    overallFeedbackMarker2
    resubmissionNote
    grade
    status
  }
  ${SUBMISSION_FILE}
`
