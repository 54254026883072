import { COURSE_PROVIDER } from 'gql/fragments/provider'
import gql from 'graphql-tag'
import { ASSESSMENT } from './assessment'
import { CONTINUE_ON_STEP_REFERENCE, COURSE } from './course'
import { COURSE_SEGMENT_BASE } from './segmentBase'
import { COURSE_STEP_BASE } from './stepBase'

export const COURSE_EDITION = gql`
  fragment courseEditionFields on CourseEdition {
    id
    title
    slug
    overview
    imageUrl
    dateStart
    dateEnd
    status
    completedPercent
    canView
    assessments {
      ...assessmentFields
    }
    course {
      ...courseFields
      pages {
        id
        title
        slug
      }
    }
    courseSegments {
      ...courseSegmentBaseFields
    }
    continueOnStep {
      ...continueOnStepReferenceFields
    }
  }
  ${COURSE}
  ${COURSE_SEGMENT_BASE}
  ${COURSE_STEP_BASE}
  ${ASSESSMENT}
  ${COURSE_PROVIDER}
  ${CONTINUE_ON_STEP_REFERENCE}
`
