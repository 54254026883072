import { PUBLIC_USER } from 'gql/fragments/user'
import gql from 'graphql-tag'

export const COURSE_STEP_COMMENT_BASE = gql`
  fragment courseStepCommentBaseFields on CourseStepComment {
    id
    createdAt
    updatedAt
    comment
    user {
      ...publicUserFields
    }
    status
    commentType
    provider {
      id
      title
      colour
      imageUrl
    }
    canEdit
    canDelete
  }
`

export const COURSE_STEP_COMMENT = gql`
  fragment courseStepCommentFields on CourseStepComment {
    ...courseStepCommentBaseFields
    replies {
      ...courseStepCommentBaseFields
    }
    parent {
      id
    }
  }
  ${COURSE_STEP_COMMENT_BASE}
  ${PUBLIC_USER}
`
