import gql from 'graphql-tag'

export const COURSE_STEP_BASE = gql`
  fragment courseStepBaseFields on CourseStep {
    id
    type: __typename
    title
    reference
    status
    canComplete
    canUncomplete
    canView
    canComment
    tags {
      type
      label
    }
  }
`
