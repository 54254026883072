import gql from 'graphql-tag'

export const COURSE_SEGMENT_BASE = gql`
  fragment courseSegmentBaseFields on CourseSegment {
    id
    title
    reference
    completedPercent
    hasBegun
    canView
    isBreak
  }
`
