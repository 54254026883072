import gql from 'graphql-tag'
import { COURSE_STEP_COMMENT } from './comment'
import { COURSE_STEP_REFERENCE } from './course'
import { COURSE_EDITION } from './edition'
import { COURSE_SEGMENT_BASE } from './segmentBase'
import { COURSE_STEP_BASE } from './stepBase'

export const COURSE_STEP = gql`
  fragment courseStepFields on CourseStep {
    ...courseStepBaseFields
    nextStep {
      ...courseStepReferenceFields
    }
    previousStep {
      ...courseStepReferenceFields
    }
    courseSection {
      courseSegment {
        ...courseSegmentBaseFields
        courseEdition {
          ...courseEditionFields
        }
      }
    }
    comments {
      ...courseStepCommentFields
    }
  }
  ${COURSE_STEP_BASE}
  ${COURSE_STEP_COMMENT}
  ${COURSE_STEP_REFERENCE}
  ${COURSE_SEGMENT_BASE}
  ${COURSE_EDITION}
`
