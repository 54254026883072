import gql from 'graphql-tag'

export const COURSE_PAGE = gql`
  fragment coursePageFields on CoursePage {
    id
    title
    slug
    contentJson
  }
`
