import gql from 'graphql-tag'

export const SAC_SUBMISSION_FILE = gql`
  fragment sacSubmissionFileFields on AssessmentSacApplicationFile {
    id
    createdAt
    updatedAt
    filename
    status
    type
    canDownload
    downloadUrl
  }
`

export const SAC_SUBMISSION = gql`
  fragment sacSubmissionFields on AssessmentSacApplication {
    id
    status
    dateOfEstimatedCompletion
    extendedDateTime
    howMuchCompletedSoFar
    reasonForApplication
    statementOfExceptionalCircumstances
    files {
      ...sacSubmissionFileFields
    }
    comments
  }
  ${SAC_SUBMISSION_FILE}
`
