import gql from 'graphql-tag'
import { SAC_SUBMISSION } from './sacSubmission'
import { SUBMISSION } from './submission'

export const ASSESSMENT = gql`
  fragment assessmentFields on Assessment {
    id
    type
    title
    submissionDueDateTime
    descriptionJson
    assessmentCriterias {
      id
      criteria
      description
      criteriaMi
      descriptionMi
    }
    group
    status
    canSubmit
    canApplySac
    submission {
      ...assessmentSubmissionFields
    }
    canRevoke
    hasPendingRevoke
    sacApplication {
      ...sacSubmissionFields
    }
    grade
    submissions(limit: 2) {
      ...assessmentSubmissionFields
    }
  }
  ${SUBMISSION}
  ${SAC_SUBMISSION}
`
